$(document).ready(function() {
    $('#flash-overlay-modal').modal();
    $(document).ready(function() {
        $('#select-multiple').multiselect();
//            $('#example').dataTable();
    });
    $('.multiple').multiselect({
        includeSelectAllOption: true,
        selectAllJustVisible: false,
        nonSelectedText: 'Seleccione una opcion!',
        selectAllText: 'Seleccionar Todos!'
    });
    $('#example-single-selected').multiselect();

    $('.input-daterange input').each(function() {
        $(this).datepicker('clearDates');
    });
});
